import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { CustomSelect } from "components/common/CustomSelect";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import { TableColumn } from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as networkingActions from "modules/networking/actions";
import {
  isPublicIPsCacheUpdatingSelector,
  networkUsageSelector,
  publicIPsCashDataSelector,
  publicIPsSelector,
  publicIPsTotalSelector
} from "modules/networking/selectors";
import { PublicIP } from "modules/networking/types";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import * as pollingActions from "modules/polling/actions";
import {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isNumber } from "typeGuards/isNumber";
import { useDebounce } from "usehooks-ts";
import { downloadFile } from "utils/downloadFile";
import { generateSearchString } from "utils/generateSearchString";
import { getSelectOption } from "utils/getSelectOption";
import { array } from "yup";
import { appConfig } from "../../appConfig";
import { API_PATH } from "../../axios";
import {
  DEFAULT_DEBOUNCE_DELAY,
  DEFAULT_PAGINATION_LIMIT,
  MAX_PAGINATION_LIMIT,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES, TABS } from "./types";
import { Loader } from "components/common/Loader";
import { LinearProgressWithLabelBig } from "components/common/LinearProgressWithLabelBig";
import { cancelNetworkMonitoringTokens } from "modules/networking/sagas";
import React from "react";
import { roundTo } from "utils/roundTo";

const POLL_ID_PREFIX = "NETWORK_MONITORING";

const POLL_IDS = {
  ips: "IPs",
  regionNetworkUsage: "REGION_NETWORK_USAGE",
  networkUsage: "NETWORK_USAGE"
};

const title = "Network Usage";

const breadcrumbs: Breadcrumb[] = [
  { text: "Network Monitoring", url: ROUTES.NETWORK_USAGE }
];

const tableColumns: TableColumn<PublicIP>[] = [
  { key: "ip", label: "Public IP" },
  { key: "type", label: "Type" },
  { key: "region", label: "Region" },
  { key: "project_id", label: "Project ID" },
  { key: "project_name", label: "Project Name" },
  { key: "user_name", label: "Project Owner" },
  { key: "email", label: "Email" }
];

export const NetworkUsage: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const networkUsage = useSelector(networkUsageSelector);

  const publicIPs = useSelector(publicIPsSelector);
  const publicIPsTotal = useSelector(publicIPsTotalSelector);
  const publicIPsCashData = useSelector(publicIPsCashDataSelector);
  const prevPublicIPsCashData = usePrevious(publicIPsCashData);
  const [isRefreshButtonDisabled, setIsRefreshButtonDisabled] = useState(false);

  const isPublicIPsCacheUpdating = useSelector(
    isPublicIPsCacheUpdatingSelector
  );
  const prevIsPublicIPsCacheUpdating = usePrevious(isPublicIPsCacheUpdating);

  const queryParams = new URLSearchParams(location.search);
  const pageParam = Number(queryParams.get("page"));
  const [page, setPage] = useState<number>(
    Number.isInteger(pageParam) && pageParam > 0 ? pageParam - 1 : 0
  );
  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );
  const itemsParam = Number(queryParams.get("items"));
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    Number.isInteger(itemsParam) &&
      itemsParam > 0 &&
      itemsParam <= MAX_PAGINATION_LIMIT
      ? itemsParam
      : DEFAULT_PAGINATION_LIMIT
  );
  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(Number(event.target.value));
      setPage(0);
    },
    []
  );

  const searchParams = queryParams.get("search");
  const [search, setSearch] = useState<string>(
    searchParams ? String(searchParams) : ""
  );
  const debouncedSearch = useDebounce(search, DEFAULT_DEBOUNCE_DELAY);
  const prevDebounceSearch = usePrevious(debouncedSearch);
  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    // setPage(0);
  }, []);

  const tableRegionsOptions = (
    appConfig.availableRegions
      ? [
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const regionParams = queryParams.get("region");
  const [showOnly, setShowOnly] = useState<string>(
    regionParams ? String(regionParams) : tableRegionsOptions[0].value
  );

  const handleChangeFilter = useCallback((value: string) => {
    setShowOnly(value);
    setPage(0);
  }, []);

  const NETWORK_MONITORING_TAB_TITLES: {
    [key in TABS]: { title: string; disabled: boolean };
  } = useMemo(
    () => ({
      [TABS.USAGE]: {
        title: "Usage",
        disabled: false
      },
      [TABS.PUBLIC_IPs]: {
        title: "Public IPs",
        disabled: false
      }
    }),
    []
  );

  const activeTabIndexFromParam = Object.keys(
    NETWORK_MONITORING_TAB_TITLES
  ).find(
    (key) =>
      NETWORK_MONITORING_TAB_TITLES[key] &&
      String(NETWORK_MONITORING_TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.USAGE) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
    // setPage(0);
  }, []);

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.GENERATE_CSV_FILE, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleDialogOpen = useCallback(
    (dialogType: DIALOG_TYPES, id?: string) => {
      if (id) setSelectedItemId(id);
      setDialog({
        type: dialogType,
        isOpened: true
      });
    },
    []
  );

  const prevPage = usePrevious(page);
  const prevRowsPerPage = usePrevious(rowsPerPage);
  const prevShowOnly = usePrevious(showOnly);
  const prevActiveTabIndex = usePrevious(activeTabIndex);

  const handleConfirmGenerateButtonClick = useCallback(
    (data: { region: SelectOption[] }) => {
      const regionsString = data.region.map((item) => item.value).join(",");
      downloadFile(
        `${API_PATH}gotham-governor/method/admin/ips/csv?regions=${regionsString}`
      );
      handleCloseDialog();
      dispatch(
        notificationsActions.showNotification({
          title: "CSV file is generating. Download will start soon.",
          type: NOTIFICATION_TYPES.INFO
        })
      );
    },
    [dispatch, handleCloseDialog]
  );

  const handleConfirmUpdateCacheData = useCallback(() => {
    dispatch(networkingActions.updateCachePublicIPs.started());
    setIsRefreshButtonDisabled(true);
  }, [dispatch]);

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.GENERATE_CSV_FILE]: {
      onConfirm: handleConfirmGenerateButtonClick,
      title: "Generate CSV file",
      confirmButtonLabel: "Generate",
      fields: [
        {
          name: "region",
          type: FIELD_TYPES.MULTISELECT,
          label: "Region",
          options: tableRegionsOptions,
          defaultValue: tableRegionsOptions,
          rules: array(selectOptionSchema)
        }
      ]
    }
  };

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.ips}`,
        action: networkingActions.getPublicIPs.started({
          region: showOnly,
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          ...(debouncedSearch ? { search: debouncedSearch } : {})
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.networkUsage}`,
        action: networkingActions.getNetworkUsage.started({ region: "all" })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(networkingActions.clear());
  });

  useEffect(() => {
    if (isNumber(publicIPsTotal) && rowsPerPage * page > publicIPsTotal) {
      setPage(0);
    }
  }, [publicIPsTotal, rowsPerPage, page]);

  useEffect(() => {
    if (publicIPsCashData !== prevPublicIPsCashData) {
      setIsRefreshButtonDisabled(false);
    }
  }, [prevPublicIPsCashData, publicIPsCashData]);

  useEffect(() => {
    switch (activeTabIndex) {
      case TABS.USAGE:
        history({
          search: generateSearchString({
            tab: NETWORK_MONITORING_TAB_TITLES[TABS.USAGE].title
          })
        });
        break;
      case TABS.PUBLIC_IPs:
        history({
          search: generateSearchString({
            tab: NETWORK_MONITORING_TAB_TITLES[TABS.PUBLIC_IPs].title,
            region: String(showOnly),
            page: String(page + 1),
            items: String(rowsPerPage),
            ...(debouncedSearch ? { search: debouncedSearch } : {})
          })
        });
        break;
    }
  }, [
    history,
    activeTabIndex,
    dispatch,
    NETWORK_MONITORING_TAB_TITLES,
    showOnly,
    page,
    rowsPerPage,
    debouncedSearch
  ]);

  useEffect(() => {
    let offset = page * rowsPerPage;

    if (
      (prevPage !== undefined && prevPage !== page) ||
      (prevRowsPerPage !== undefined && prevRowsPerPage !== rowsPerPage) ||
      (prevShowOnly !== undefined && prevShowOnly !== showOnly) ||
      (prevDebounceSearch !== undefined &&
        prevDebounceSearch !== debouncedSearch)
    ) {
      if (debouncedSearch !== prevDebounceSearch) {
        setPage(0);
        offset = 0;
      }
      history({
        search: generateSearchString({
          tab: String(activeTabIndex),
          page: String(page + 1),
          items: String(rowsPerPage),
          region: String(showOnly),
          ...(debouncedSearch ? { search: debouncedSearch } : {})
        })
      });
      // Cancel the previous requests due to new parameters
      cancelNetworkMonitoringTokens["listPublicIPs"]?.cancel(
        "Cancel the previous request"
      );
      cancelNetworkMonitoringTokens["listPublicIPs"] = null;

      // stop polling
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.ips}`
        })
      );
      // clear state - to add loaders if change some of the options
      dispatch(networkingActions.clearPublicIPsList());

      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.ips}`,
          action: networkingActions.getPublicIPs.started({
            region: showOnly,
            offset: offset,
            limit: rowsPerPage,
            ...(debouncedSearch ? { search: debouncedSearch } : {})
          })
        })
      );
    }
  }, [
    prevDebounceSearch,
    debouncedSearch,
    dispatch,
    page,
    rowsPerPage,
    showOnly,
    history,
    prevPage,
    prevRowsPerPage,
    prevShowOnly,
    activeTabIndex
  ]);

  const renderSimpleMetric = (title, value, unit, helperText = "") => {
    return (
      <s.SimpleMetricsContainer>
        <Tooltip title={helperText} arrow placement={"top"}>
          <s.SimpleMetricItem>
            <s.SimpleMetricTitle>{title}</s.SimpleMetricTitle>
            <s.SimpleMetricValue>
              {value} {unit}
            </s.SimpleMetricValue>
          </s.SimpleMetricItem>
        </Tooltip>
      </s.SimpleMetricsContainer>
    );
  };

  const tabContent = [
    <>
      <s.MetricsContainerBig variant={"outlined"}>
        {!networkUsage ? (
          <Loader text={"Loading data..."} />
        ) : (
          <React.Fragment>
            {networkUsage.map((data, index) => (
              <s.Container key={index}>
                <s.MetricTitleContainer>
                  <s.MetricTitle>{data.region}</s.MetricTitle>
                </s.MetricTitleContainer>
                <s.MetricTitleContainer>
                  <s.DetailsInfoColored>
                    {/* <s.InfoSpan>
                      CIDR: {data.all_public_subnets[0].cidr}
                    </s.InfoSpan> */}
                  </s.DetailsInfoColored>
                </s.MetricTitleContainer>
                <LinearProgressWithLabelBig
                  value={data.used_ip_count}
                  maxValue={data.total_ip_count}
                  unit="ips"
                  // bottomCaption
                />
                <s.MetricRowContainer>
                  {renderSimpleMetric(
                    "CIDR",
                    data.all_public_subnets[0].cidr,
                    ""
                  )}
                  {renderSimpleMetric(
                    "Total IPs",
                    `${data.total_ip_count}`,
                    ""
                  )}
                  {renderSimpleMetric(
                    "Used IPs",
                    `${data.used_ip_count} (${roundTo((data.used_ip_count / data.total_ip_count) * 100, 0)}%)`,
                    ""
                  )}
                  {renderSimpleMetric(
                    "Free IPs",
                    `${data.total_ip_count - data.used_ip_count} (${roundTo(((data.total_ip_count - data.used_ip_count) / data.total_ip_count) * 100, 0)}%)`,
                    ""
                  )}
                </s.MetricRowContainer>
              </s.Container>
            ))}
          </React.Fragment>
        )}
      </s.MetricsContainerBig>
    </>,
    <>
      <Table
        isSearchEnabled={true}
        searchString={search}
        searchLabel={"Search by IP"}
        onChangeSearch={handleChangeSearch}
        isSortingEnabled={true}
        isPaginationEnabled={true}
        page={page}
        count={publicIPsTotal || 0}
        rowsPerPage={rowsPerPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rows={publicIPs || []}
        columns={tableColumns}
        isLoading={!publicIPs}
        toolbarItems={
          <>
            <Box style={{ display: "flex", flex: 1 }}>
              <span>
                <Button
                  onClick={() =>
                    handleDialogOpen(DIALOG_TYPES.GENERATE_CSV_FILE)
                  }
                  variant={"contained"}
                  style={{ width: "200px" }}
                  // disabled={!publicIPs}
                >
                  Export CSV file
                </Button>
              </span>
              <CustomSelect
                label={"Region"}
                options={tableRegionsOptions}
                value={showOnly}
                defaultValue={tableRegionsOptions[0].value}
                onChange={handleChangeFilter}
                margin="0 10px 0 10px"
              />
            </Box>
            <s.CashContainer2>
              <s.ActionsContainer>
                {publicIPsCashData && (
                  <Tooltip title={"Report updated at:"} placement={"top"} arrow>
                    <span>
                      <s.Description>
                        Report date: {publicIPsCashData}
                      </s.Description>
                    </span>
                  </Tooltip>
                )}

                <Tooltip title={"Refresh report"} placement={"top"} arrow>
                  <span>
                    <IconButton
                      onClick={handleConfirmUpdateCacheData}
                      color={"inherit"}
                      disabled={isRefreshButtonDisabled || !publicIPs}
                    >
                      <s.RotatingRefreshIcon
                        $isSpinning={isRefreshButtonDisabled}
                      />
                      {/* <RefreshIcon /> */}
                    </IconButton>
                  </span>
                </Tooltip>
              </s.ActionsContainer>
            </s.CashContainer2>
          </>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  ];

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>
      <s.TabsContainer>
        <Tabs value={activeTabIndex} onChange={handleChangeTab}>
          {Object.values(NETWORK_MONITORING_TAB_TITLES).map((tab) => (
            <Tab key={tab.title} label={tab.title} disabled={tab.disabled} />
          ))}
        </Tabs>
      </s.TabsContainer>
      {tabContent[activeTabIndex]}
    </>
  );
};
