import { Paper, SvgIconProps } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled, { css, keyframes } from "styled-components";

export const ContactSupportLinkText = styled(Typography)`
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.4em;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CashContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const CashContainer2 = styled.div`
  display: flex;
  margin-right: 10px;
  justify-content: flex-end;
`;

export const SummaryInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const Description = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: smaller;
`;

export const MetricsContainer = styled(Paper)`
  display: flex;
  /* order: 6; */
  /* width: 100%; */
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing(0)};
  padding: ${({ theme }) => theme.spacing(2)};
  min-height: 90px;
  /* border: none; */
`;

export const MetricsContainerBig = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(2)};
  min-height: 350px;
`;

export const MetricContainer = styled.div`
  /* width: calc(25% - ${({ theme }) => theme.spacing(2)}); */
  /* min-width: 350px; */
  min-width: 200px;
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(1)};
`;

export const Container = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const MetricRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;
  /* margin-bottom: ${({ theme }) => theme.spacing(10)}; */
`;

export const SimpleMetricTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const SimpleMetricValue = styled.div`
  font-size: 16px;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.secondary.dark
      : theme.palette.secondary.dark};
  font-weight: 500;
  transition: color 0.3s ease;
`;

export const SimpleMetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const SimpleMetricItem = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
  text-align: center;
  width: 200px;
  background: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? `0px 1px 3px 0px ${theme.palette.grey["900"]}, 0px 1px 1px 0px ${theme.palette.grey["900"]}, 0px 2px 1px -1px ${theme.palette.secondary.main}`
      : theme.shadows[1]};
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.mode === "dark"
        ? theme.palette.grey[900]
        : theme.palette.action.hover};
    box-shadow: ${({ theme }) =>
      theme.palette.mode === "dark"
        ? `0px 1px 1px -2px ${theme.palette.grey["900"]}, 0px 1px 5px 0px ${theme.palette.grey["900"]}, 0px 3px 1px -1px ${theme.palette.secondary.main}`
        : `${theme.shadows[3]}`};

    ${SimpleMetricValue} {
      color: ${({ theme }) =>
        theme.palette.mode === "dark"
          ? theme.palette.secondary.main
          : theme.palette.secondary.dark};
    }
  }
`;

export const TabsContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(1.5, 0)};
`;

export const MetricTitle = styled(Typography)`
  /* margin-right: ${({ theme }) => theme.spacing(0.5)}; */
  /* color: ${({ theme }) => theme.palette.primary.dark}; */
  font-weight: 500;
  text-align: center;
`;

export const MetricTitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  text-align: center;
  /* align-items: center; */
  white-space: nowrap;
`;

export const InfoSpan = styled.span`
  margin-right: ${({ theme }) => theme.spacing(3)};
`;

export const DetailsInfo = styled.p`
  /* margin-right: ${({ theme }) => theme.spacing(2)}; */
  margin: ${({ theme }) => theme.spacing(0)};
  font-size: smaller;
`;

export const DetailsInfoColored = styled(DetailsInfo)`
  color: ${({ theme }) => theme.palette.primary.main};
  /* color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main}; */
  font-weight: 550;
`;

// to spin refresh Cash icon
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
interface RotatingRefreshIconProps extends SvgIconProps {
  $isSpinning?: boolean;
}
export const RotatingRefreshIcon = styled(
  RefreshIcon
)<RotatingRefreshIconProps>`
  ${({ $isSpinning }) =>
    $isSpinning &&
    css`
      animation: ${rotate} 1s linear infinite;
    `}
`;
